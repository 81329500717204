import React from 'react';

import { ScPage } from '../components/page/styled';
import { MainLayout } from '../layouts/main-layout';
import { Benefits } from '../sections/ProviderNursery/Benefits';
import { NurseryHero } from '../sections/ProviderNursery/NurseryHero';
import { ParentsHero } from '../sections/ProviderNursery/ParentsHero';
import { ProviderNurseryHero } from '../sections/ProviderNursery/ProviderNurseryHero';
import { ProviderNurseryInfo } from '../sections/ProviderNursery/ProviderNurseryInfo';
import {SEO} from "../components/Seo/Seo";

const ProviderNursery = () => {
	return (
		<MainLayout>
			<ScPage>
				<ProviderNurseryHero />
				<NurseryHero />
				<ProviderNurseryInfo />
				<Benefits />
				<ParentsHero />
			</ScPage>
		</MainLayout>
	);
};

export default ProviderNursery;

export const Head = () => (
	<SEO
		title="Join Gogeta's Nursery Scheme | Extra 12.5% Funding for Providers"
		description="Gogeta’s workplace nursery scheme gives nurseries 12.5% extra funding per parent, designed to be simple for busy managers. Join our provider network today!"
	/>
)
